var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "previewFile",
      attrs: { fullscreen: _vm.isFullscreen, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "margin-right": "30px",
            display: "flex",
            "justify-content": "space-between",
            cursor: "pointer",
          },
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", [_vm._v("附件预览")]),
          _c("Icon", {
            attrs: { type: "md-expand", size: "24", color: "#ccc" },
            on: { click: _vm.changeFull },
          }),
        ],
        1
      ),
      _vm.previewHtmlUrl && _vm.previewHtmlUrl.indexOf(".pdf") > -1
        ? _c("iframe", {
            staticClass: "pdfTemplate",
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              id: "printPdf",
              src: _vm.previewHtmlUrl,
              frameborder: "0",
            },
          })
        : _c("img", {
            staticStyle: { overflow: "hidden", width: "100%", height: "100%" },
            attrs: { src: _vm.previewHtmlUrl },
          }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }